import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Meta from "../components/meta"
import NewsletterForm from "../components/newsletter"
import Img from "gatsby-image"

export default props => {
  const post = props.data.markdownRemark

  return (
    <Layout>
      <SEO title={post.frontmatter.title} description={post.excerpt} />
      <article>
        <header>
          <h1>{post.frontmatter.title}</h1>
          <Meta date={post.frontmatter.date} timeToRead={post.timeToRead} />
          {post.frontmatter.image && (
            <Img sizes={post.frontmatter.image.childImageSharp.sizes} />
          )}
        </header>
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
      </article>
      <NewsletterForm />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        # image {
        #   childImageSharp {
        #     sizes(maxWidth: 630) {
        #       ...GatsbyImageSharpSizes
        #     }
        #   }
        # }
      }
      timeToRead
    }
  }
`
